@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --clue-gray: theme("colors.zinc[600]");
    --clue-yellow: #c9b458;
    --clue-green: #6aaa64;

    --secondary-500: #83d57b;
    --secondary-600: #75bd6f;
    --secondary-700: #6aaa64;
    --secondary-800: #4a7545;
    --secondary-900: #40663c;


    --text-col: theme("colors.slate[50]");
    --text-col-secondary: theme("colors.neutral[500]");
  }

  .og {
    --primary-600: theme("colors.slate[600]");
    --primary-700: theme("colors.slate[700]");
    --primary-800: theme("colors.slate[800]");
    --primary-900: theme("colors.slate[900]");

    --clue-gray: theme("colors.slate[600]");

  }

  .dark {
    --primary-600: theme("colors.neutral[600]");
    --primary-700: theme("colors.neutral[700]");
    --primary-800: theme("colors.neutral[800]");
    --primary-900: theme("colors.neutral[900]");
  }

  .light {
    --primary-600: theme("colors.slate[300]");
    --primary-700: theme("colors.slate[200]");
    --primary-800: theme("colors.slate[100]");
    --primary-900: theme("colors.slate[50]");

    --text-col: theme("colors.slate[900]");
  }
}

@layer utilities {
  .flex-center {
    @apply flex justify-center items-center
  }

  .full {
    @apply h-full w-full
  }

  .black-shadow {
    @apply shadow-lg
  }
}

@layer components {
  .clue {
    @apply rounded-[1.2vh] overflow-y-auto
  }

  .label {
    @apply bg-black/40 w-full text-[1rem] md:text-[1.5vh] flex-center h-[1.2rem] md:h-[1.8vh]
  }
}

.content>div>div {
  border-radius: 1.2vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  user-select: none;
  line-height: 170%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button, select, input {
  border-radius: 0.375rem;
  transition: background-color 150ms ease-in-out;
}

select, input {
  background-color: var(--primary-700);
}

button.primary {
  background-color: var(--primary-700);
}

button.secondary {
  background-color: var(--secondary-900);
}

button.primary:hover {
  background-color: var(--primary-600);
}

button.secondary:hover {
  background-color: var(--secondary-800);
}

button:disabled:hover {
  background-color: var(--primary-700);
}

button.secondary:disabled:hover {
  background-color: var(--secondary-900);
}

select option:hover {
  box-shadow: 0 0 10px 100px red inset;
}

a {
  text-decoration: underline;
}

.toggle > .switch {
  transition: all 100ms ease-out;
}

.toggle .toggle--background {
  transition: all 100ms ease-out;
}

.clue {
  perspective: 1000px;
}

.content {
  position: absolute;
  transition-property: transform;
  transform-style: preserve-3d;
}

.face {
  backface-visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}

.two {
  transform: rotateY(180deg);
}

.flip {
  transform: rotateY(180deg);
}

@keyframes slideIn {
  0% {
    transform: translateY(10em);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  0% {
    transform: translateY(0);
    opacity: 1;
  }

  100% {
    transform: translateY(10em);
    opacity: 0;
  }
}

@keyframes slideInBG {
  0% {
    background-color: rgba(0 0 0 / 0);
  }

  100% {
    background-color: rgb(0 0 0 / 0.5);
  }
}

@keyframes slideOutBG {
  0% {
    background-color: rgb(0 0 0 / 0.5);
  }

  100% {
    background-color: rgb(0 0 0 / 0);
  }
}

.sclue:hover::after {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: "test";
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(0 0 0 / 0.2);
  font-size: 4.5vh;
}

.modal {
  animation: slideIn 100ms forwards ease-out;
}

.modal.closing {
  animation: slideOut 70ms forwards ease-out !important;
}

.modalBG {
  animation: slideInBG 100ms forwards ease-out;
}

.modalBG.closing {
  animation: slideOutBG 70ms forwards ease-out !important;
}

@keyframes slideInNT {
  0% {
    transform: translateY(-5em);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOutNT {
  0% {
    transform: translateY(0em);
    opacity: 1;
  }

  100% {
    transform: translateY(5em);
    opacity: 0;
  }
}



.notification.open {
  animation: slideInNT 100ms forwards;
}

.notification.close {
  animation: slideOutNT 100ms forwards;
}

@keyframes pointCostBGIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes pointCostBGOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.close-button, .enlarge-button {
  transition: transform 200ms ease-out;
}

.close-button:hover, .enlarge-button:hover {
  transform: scale(1.1);
}


.pointCost {
  transition: 50ms ease-out;
}

.pointCostShow {
  opacity: 1;
}

.pointCostHide {
  opacity: 0;
}

#app {
  display: grid;
  grid-template:
    "navbar "
    "game   "
  ;
  grid-template-rows: 44px 1fr;
  grid-template-columns: 1fr;
  grid-gap: 1vh;
}

#game {
  grid-area: game;
}

#navbar {
  grid-area: navbar;
}

.tutorial .clueTable {
  display: grid;
  grid-gap: 1.2vh;
  grid-template:
    "title    title     title "
    "poster   actor1    year  "
    "poster   actor1    budget"
  ;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-columns: repeat(3, 1fr);

}

.clueTable {
  display: grid;
  grid-gap: 1.2vh;
  grid-template:
    "title    title     title   "
    "poster   year      rating  "
    "poster   director  budget  "
    "quote    quote     quote   "
    "actor1    actor2     actor3"
  ;
  grid-template-rows: 7fr 8fr 11fr 10fr 18fr;
  grid-template-columns: repeat(3, 1fr);
}

@media only screen and (max-width: 768px) {
  .clueTable {
    flex-grow: 1;
    display: grid;
    grid-gap: 10px;
    grid-template:
      "title    title   "
      "poster   poster  "
      "year     rating  "
      "director budget  "
      "quote    quote   "
      "actor1    actor2 "
      "actor3    actor4 "
    ;
    grid-template-rows: 7rem 38rem 7rem 10rem 10rem 19rem 19rem;
    grid-template-columns: repeat(2, 1fr);
  }

  #game {
    aspect-ratio: initial;
    width: 100%;
    margin: initial;
  }

}

.clueTable>div>div {
  min-width: 0;
}

.title {
  grid-area: title;
}

.poster {
  grid-area: poster;
}

.year {
  grid-area: year;
}

.rating {
  grid-area: rating;
}

.director {
  grid-area: director;
}

.writer {
  grid-area: writer;
}

.budget {
  grid-area: budget;
}

.quote {
  grid-area: quote;
}

.actor1 {
  grid-area: actor1;
}
.actor2 {
  grid-area: actor2;
}
.actor3 {
  grid-area: actor3;
}